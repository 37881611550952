<template>
  <b-sidebar
    id="perfect-mind-sidebar"
    body-class="custom-scroll"
    aria-labelledby="Perfect mind configuration"
    aria-label="Perfect mind configuration"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    backdrop
    right
    width="35vw"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Perfect mind configuration</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          aria-labelledby="Perfect mind configuration title"
          @click="hide"
          ><img src="../../../../assets/img/close.png" alt="close"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <PerfectMindForm
        :tenantServiceId="tenantServiceId"
        @perfectMindConfigUpdateRes="perfectMindConfigUpdateRes"
        @perfectMindIsValidConfiguration="perfectMindIsValidConfiguration"
      />
    </template>
    <template #footer="{ hide }">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="hide"
        >Close</b-button
      >
    </template>
  </b-sidebar>
</template>
<script>
export default {
  name: 'PerfectMindSidebar',
  props: ['tenantServiceId'],
  components: {
    PerfectMindForm: () => import('./PerfectMindForm.vue')
  },
  methods: {
    perfectMindIsValidConfiguration (isValid) {
      this.$store.commit('widgets/setPerfectMindConfigIsValid', isValid)
    },
    perfectMindConfigUpdateRes (res) {
      if (Object.hasOwn(res, 'data')) {
        if (res.data) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'PERFECT_MIND_UPDATE_SUCCESS',
            type: 'success'
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: res.error,
            key: false,
            type: 'danger'
          })
        }
      } else {
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'FAILED',
          type: 'danger'
        })
      }
    }
  }
}
</script>
